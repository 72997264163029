import { IconProps } from '@/types'

export function PlusIcon(prop: IconProps) {
  return (
    <svg
      width={prop.width || 24}
      height={prop.height || 25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={prop.className}>
      <path
        d="M13.3002 11.4518L18.5145 11.4518C19.2246 11.4518 19.8002 12.0274 19.8002 12.7375C19.8002 13.4476 19.2246 14.0232 18.5145 14.0232L13.3002 14.0232L13.3002 19.2376C13.3002 19.9555 12.7182 20.5376 12.0002 20.5376C11.2822 20.5376 10.7002 19.9555 10.7002 19.2376L10.7002 14.0232L5.45251 14.0232C4.76088 14.0232 4.2002 13.4625 4.2002 12.7709C4.2002 12.0424 4.79078 11.4518 5.5193 11.4518H10.7002V6.2375C10.7002 5.51953 11.2822 4.9375 12.0002 4.9375C12.7182 4.9375 13.3002 5.51953 13.3002 6.2375L13.3002 11.4518Z"
        fill="white"
      />
    </svg>
  )
}
