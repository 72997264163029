'use client'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'

import clsxm from '@/lib/clsxm'

import Button from '@/components/buttons/Button'
import NextImage from '@/components/NextImage'
import { PlusIcon } from '@/components/svg-icons'

import CommunityGroupPng from '@/assets/home/community-front-group.png'
import CommunityGroupMobilePng from '@/assets/home/community-group-mobile.png'

function CommunitySection() {
  const { t, lang } = useTranslation()
  return (
    <section className={clsxm('bg-tertiaryGreen-50')}>
      <div className="lg:hidden">
        <NextImage
          placeholder="blur"
          // blurDataURL="iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8v1LnPwAHWALVX891zgAAAABJRU5ErkJggg=="
          src={CommunityGroupMobilePng}
          alt="Community"
        />
      </div>
      <div className={clsxm('container mx-auto lg:px-6 py-12 lg:flex lg:flex-row lg:items-center')}>
        <div className="lg:justify-center-center flex flex-col gap-4 px-4 md:px-8 text-center lg:flex lg:max-w-[50%] lg:flex-col lg:text-left">
          <h3 className="font-roboto leading-12 text-4xl font-medium lg:text-5xl lg:leading-h1">{t(`home_community_title`)}</h3>
          <p className="font-roboto text-2xl font-normal lg:text-3xl">{t(`home_community_para`)}</p>
          <p className="font-roboto text-2xl font-medium lg:text-3xl">{t(`home_community_para_bold`)}</p>
          <Link href={`/${lang}/communities-b2b`} className="mt-8">
            <Button
              type="button"
              variant="primary"
              size="base"
              className="w-full justify-center lg:max-w-[300px]"
              leftIcon={PlusIcon}>
              {t('Create Community')}
            </Button>
          </Link>
        </div>
        <div className="relative hidden h-[650px] w-[800px] lg:block">
          <NextImage
            layout="fill"
            objectFit="contain"
            placeholder="blur"
            // blurDataURL="iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8v1LnPwAHWALVX891zgAAAABJRU5ErkJggg=="
            src={CommunityGroupPng}
            alt="Community"
          />
        </div>
      </div>
    </section>
  )
}

export default CommunitySection
