'use client'
import { useRouter } from 'next/navigation'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'

import { useUser } from '@/hooks/useUser'

import Button from '@/components/buttons/Button'

function HomeForumListHeader({ location }) {
  const { t, lang } = useTranslation()
  const router = useRouter()
  const { user } = useUser()

  const handleClick = () => {
    if (user) {
      router.push(`/${lang}/${location}/forums`)
    } else {
      router.push(`/${lang}/auth/login?after_signup_route=/${lang}/${location}/forums`)
    }
  }

  return (
    <>
      <div className="flex items-center justify-between px-4">
        <h2 className="text-text-800 text-3xl font-medium sm:flex-none lg:text-5xl">{t(`Forums`)}</h2>

        <Button type="button" variant="primary" size="md" onClick={handleClick}>
          {t(`All_men`)}
        </Button>
      </div>
    </>
  )
}

export default HomeForumListHeader
